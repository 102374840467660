@use "./config" as *;

.waf-rules {
    padding: 0;
}

.points-system-section {
    margin: calc(var(--full-space) * 2) 0;
    padding: 0;

    .head {
        background: var(--kabaddi-secondary-color);
        border-radius: var(--half-radius);

        .title {
            height: 3.5rem;
            margin-bottom: 0;
            color: var(--white-color);
            font: 1.2rem/1.2 $font-bold;
            padding: 0 var(--full-space);
            @include flex-config(flex, null, null, center);
        }
    }

    .body {
        background: var(--light-grey-color);
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        border: 0.1rem solid var(--light-grey-color);
    }

    .points {
        &-section {
            padding: 0 var(--full-space);
        }

        &-list {
            flex-wrap: wrap;
            @include flex-config(flex, null, null, center);
        }

        &-item {
            flex-basis: 100%;
            width: 100%;
            flex-shrink: 0;
            height: 4.6rem;
            position: relative;
            @include flex-config(flex, null, space-between, center);

            &:not(:last-child)::after {
                content: '';
                width: 100%;
                height: 0.1rem;
                background-color: rgba(var(--black-color-rgb), 0.2);
                bottom: 0;
                @include center(horizontal);
            }

        }

        &-label {
            font: 1.2rem/1.2 $font-medium;
            color: var(--kabaddi-secondary-color);
            margin-bottom: 0;
        }

        &-count {
            font: 1.2rem/1.2 $font-bold;
            color: var(--kabaddi-accent-color);
        }
    }

    .team-qualify {
        .points-count {
            display: inline-block;
            width: 100%;
            font: 1.2rem/1 $font-bold;
            padding: var(--half-space) 1rem;
            background: var(--kabaddi-primary-color);
            color: var(--kabaddi-secondary-color);
            margin-bottom: 0;
            text-align: center;
        }
    }
}

@media screen and (min-width: $tablet-min-width) {
    .layout-wrapper{
        width: var(--container-max-width);
        margin: 0 auto;
    }
    .points-system-section {
        .points{
            &-item{
                height: 6.4rem;
                padding: 0 var(--half-space);
                @include flex-config(null,null,center,null);
                &:not(:last-child)::after {
                    width: 0.1rem;
                    height: 100%;
                    right: 0;
                    left: unset;
                    bottom: unset;
                    @include center(vertical);
                }
            }
            &-section{
                padding: var(--full-space);
            }
        }
    }
}

@media screen and (min-width: $desktop-min-width) {
    .points-system-section {
        .points {
            &-item {
                flex-basis: 25%;
                width: 25%;

            }
        }
    }

}