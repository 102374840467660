@use "./config" as *;

.standing-page {
  padding: 0 var(--full-space);
  z-index: inherit;
  .waf-head {
    @include flex-config(null, null, null, center);
  }
  .standings-table {
    border: 0.1rem solid rgba(var(--light-grey-color-rgb), 0.2);
    border-top: unset;
  }

  .table {
    &-head {
      height: 3.5rem;

      .table-data {
        p,
        .text {
          color: var(--white-color);
        }
      }
    }

    &-body {
      .table {
        &-row {
          &:nth-child(odd) {
            .table-data {
              background-color: var(--light-grey-color);
              border-top: 0.1rem solid rgba(var(--light-grey-color-rgb), 0.2);
              border-bottom: 0.1rem solid rgba(var(--light-grey-color-rgb), 0.2);
            }
          }

          &:last-child {
            .table-data {
              border-bottom: 0.1rem solid rgba(var(--light-grey-color-rgb), 0.3);
            }
          }
        }
      }
    }
  }

  /* kabbadi standing page */
  &-kabaddi {
    .table-right .table-data {
      width: calc(100% / 5);
    }
    // form guide
    .form {
      display: none;
    }
    // form guide
  }
  /* kabbadi standing page */
}
.wpl-page{
  .standing-page{
    .standings-table{
      margin-bottom: calc(var(--full-space) * 2);
    }
  }
 .table-right{
  overflow-x: unset;
  .table-data{
    width: 20%;
    &.net-rr{
      @extend %flex-n-c;
    }
  }
 }
}

.waf {
  &-formguide {
    display: none;
    .points {
      &-item {
        @include flex-config(flex, null, flex-end, null);
        p {
          font-family: $font-medium;
          font-size: 1.2rem;
          padding: 0 0.5rem;
          border-right: 1px solid var(--dark-grey-color);
          @include flex-config(flex, null, center, center);
          &::before {
            content: "";
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            margin-right: 0.5rem;
          }
        }
        .points-label {
          border-right: none;
          padding-right: 0;
          &::before {
            display: none;
          }
        }
        .win {
          &::before {
            background-color: var(--win-color);
          }
        }
        .tie {
          &::before {
            background-color: var(--draw-color);
            border: 0.1rem solid var(--dark-grey-color);
          }
        }
        .loss {
          border-right: none;
          &::before {
            background-color: var(--lost-color);
          }
        }
      }
    }
  }
}

@include standing-page-theme(kabaddi);

@media screen and (min-width: $tablet-min-width) {
  .standing-page {
    // z-index: var(--standing-z);
    overflow: hidden;
    position: relative;
    padding: 0;
    
    .layout-wrapper {
      max-width: var(--container-max-width);
      margin: 0 auto;
    }

    .table {
      &-left {
        width: 25%;

        .table-head {
          @include border-radius(0 0 0 var(--half-radius));
        }
      }

      &-right {
        overflow-x: scroll;
        width: 75%;

        .table-data {
          width: calc(100% / 9);
        }
      }
    }

    /* kabbadi standing page */
    &-kabaddi {
      .table-right .table-data {
        width: calc(100% / 5);
      }
      .table-data {
        &.form {
          flex-basis: 40%;
          width: 40%;
          display: flex;
        }

        .form {
          &-listing {
            list-style: none;
            flex-basis: 100%;
            width: 100%;
            height: 100%;
            @include flex-config(flex, null, center, center);
          }

          &-item {
            width: 2.7rem;
            height: 2.7rem;
            flex-shrink: 0;
            border-radius: 50%;
            margin: 0 calc(var(--half-space) / 2);
            @include flex-config(flex, null, center, center);

            p {
              font: 1.2rem/0 $font-medium;
              color: var(--white-color);
              margin-bottom: 0;
            }

            &.win {
              background: var(--win-color);
            }

            &.lost {
              background: var(--lost-color);
            }

            &.draw {
              background: var(--draw-color);
              border: 0.2rem solid var(--light-grey-color);
              p {
                color: rgba(var(--black-color-rgb), 0.7);
              }
            }
          }
        }
      }
    }
    /* kabbadi standing page */
  }

  .standing-page{
    .standings-table{
      margin-bottom: calc((var(--full-space) * 2) + 1rem);
    }
  }

  .waf {
    &-formguide {
      display: block;
      .points {
        &-item {
          p {
            padding: 0 var(--full-space);
          }
        }
      }
    }
  }
  .wpl-page{
    .table-right{
      overflow-x: scroll;
     .table-data{
       width: 13%;
     }
    }
   }
}

@media screen and (min-width: $desktop-min-width) {
  .standing-page {
    &-kabaddi {
      .table-right {
        overflow-x: unset;
        .table-data {
          width: calc(78% / 7);
          &.form {
            flex-basis: 22%;
            width: 22%;
          }
        }
      }
    }
  }
  .wpl-page{
    .table-right{
     .table-data{
       width: 13%;
     }
    }
   }
}
